import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GlobalService {
    constructor() {

    }
    public onShowInvisibleDiv = new BehaviorSubject<boolean>(false);
    public onToggleVisibilityCartPreviewPopup = new BehaviorSubject<boolean>(false);
    public onToggleVisibilityUserLoggedPopup = new BehaviorSubject<boolean>(false);

    private showInvisiblediv() {
        this.onShowInvisibleDiv.next(true);
    }
    private hideInvisiblediv() {
        this.onShowInvisibleDiv.next(false);
    }

    public onClickInvisibleDiv() {
        this.onToggleVisibilityCartPreviewPopup.next(false);
        this.onToggleVisibilityUserLoggedPopup.next(false);
        this.hideInvisiblediv();
    }
    public openCartPreview() {
        this.showInvisiblediv();
        this.onToggleVisibilityCartPreviewPopup.next(true);
    }
    public openUserLoggedPopup(){
        this.showInvisiblediv();
        this.onToggleVisibilityUserLoggedPopup.next(true);
    }
}