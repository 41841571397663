import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from 'src/environments/environment';


@Injectable()
export class InvocationService {

  private backendUrl: string;
  public constructor(private http: HttpClient) {
    this.backendUrl = environment.backend;
  }
  PutRequest = <TResult, TSource>(request: string, body: TSource): Promise<TResult> => {
    return this.methodDispatcher(this.http.put(`${this.backendUrl}${request}`, body));
  }
  PatchRequest = <TResult, TSource>(request: string, body: TSource): Promise<TResult> => {
    return this.methodDispatcher(this.http.patch(`${this.backendUrl}${request}`, body));
  }
  UpdateRequest = <TResult, TSource>(request: string, body: TSource): Promise<TResult> => {
    return this.methodDispatcher(this.http.put(`${this.backendUrl}${request}`, body));
  }
  DeleteRequest = <TResult>(request: string): Promise<TResult> => {
    return this.methodDispatcher(this.http.delete(`${this.backendUrl}${request}`));
  }
  GetRequest = <TResult>(request: string): Promise<TResult> => {
    return this.methodDispatcher(this.http.get<TResult>(`${this.backendUrl}${request}`));
  }
  PostRequest = <TResult, TSource>(request: string, body: TSource): Promise<TResult> => {
    const _headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.methodDispatcher(this.http.post(`${this.backendUrl}${request}`, body, { headers: _headers }));
  }

  private methodDispatcher = <TResult>(obs: Observable<Object>): Promise<TResult> => {
    return obs
      .pipe(catchError(this.handleError))
      .toPromise();
  }
  private handleError(error: HttpErrorResponse): Promise<any> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return Promise.reject(error);
  }

}