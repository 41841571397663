import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvocationService } from './services/common/invocation.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    providers: [
        InvocationService
    ]
})
export class CoreModule { }