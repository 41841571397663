export const environment = {
  production: false,
  backend: 'https://dev.jadeapi.com/api/jade-api',
  offersImagesPath: 'https://jadeservice.azurewebsites.net/MyOffers/',
  appKeyPusher: 'b8a7fd165e1bd62614e4',
  azulSite: 'https://pruebas.azul.com.do/PaymentPage/',
  azulMerchantId: '99999994',
  cardnetUrl: 'https://lab.cardnet.com.do/servicios/tokens/v1/Scripts/PWCheckout.js',
  cardnetCaptureUrl: 'https://lab.cardnet.com.do/servicios/tokens/v1/Capture/',
  cardnetPublicKey: 'mfH9CqiAFjFQh_gQR_1TQG_I56ONV7HQ',
  cardnetPublicKeyAnonymous: 'mfH9CqiAFjFQh_gQR_1TQG_I56ONV7HQ'//TODO poner publica desarrollo anonima
};